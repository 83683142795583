import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from '../../../../environments/enviroment';
import { Login } from '../interfaces/login';
import { IUserInfo, IverifyCode2FASettingsRequest } from '../../users/interfaces/user-info';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private _httpClient = inject(HttpClient);
  private _Router = inject(Router);

  public userInfo: BehaviorSubject<IUserInfo | null> = new BehaviorSubject<IUserInfo | null>(null);
  private userToken = sessionStorage.getItem('adminUserToken');

  loginUser(formData: Login): Observable<any> {
    return this._httpClient.post(
      `${environment.apiUrl}/v1/auth/admin-login`,
      formData
    );
  }

  changePassword(formData: any): Observable<any> {
    console.log('changePassword userToken', this.userToken);

    return this._httpClient.post(
      `${environment.apiUrl}/v1/staff/change-password`,
      formData
    );
  }

  // get user information example name, avatar
  getUserInfo(): Observable<any> {
    return this._httpClient.get(`${environment.apiUrl}/v1/staff/validate`);
  }

  // get 2Fa Secrets
  getTwoFaSecrets(): Observable<any> {
    return this._httpClient.patch(`${environment.apiUrl}/v1/staff/2fa`, {});
  }

  sendVerifyCode(formData: IverifyCode2FASettingsRequest): Observable<any> {
    return this._httpClient.post(
      `${environment.apiUrl}/v1/staff/validate-2fa/${formData.code}`,
      {}
    );
  }

  // 2fa Code
  sendTwoFaCode(formData: any): Observable<any> {
    return this._httpClient.get(
      `${environment.apiUrl}/v1/auth/admin-validate-2fa/${formData.userHash}/${formData.twofaCode}`
    );
  }

  // to disable the 2fa authentication way
  disableTwoFaAuth(): Observable<any> {
    return this._httpClient.patch(
      `${environment.apiUrl}/v1/staff/disable-2fa`,
      {}
    );
  }

  // upload new user avatar
  uploadNewAvatar(avatar: string): Observable<any> {
    return this._httpClient.patch(
      `${environment.apiUrl}/v1/staff/change-avatar`,
      { avatar: avatar }
    );
  }

  logout() {
    sessionStorage.clear();
    this._Router.navigate(['/auth/login']);
  }
}
